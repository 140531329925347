<template>
    <button
        class="button"
        :disabled="disabled"
        @click="$emit('click')"
    >
        {{ description }}
        <i :class="`fal fa-${icon}`" />
    </button>
</template>

<style lang="scss">

.button {
    position:      relative;
    background:    #fff;
    padding:       18px;
    width:         250px;
    border-radius: 3px;
    outline:       none;
    border:        none;
    font-size:     20px;
    margin:        10px 0;
    color:         var(--dynamic-title-blue-gray);

    i {
        position:         absolute;
        right:            -42px;
        top:              -1px;
        font-size:        30px;
        padding:          15px;
        width:            30px;
        height:           31px;
        color:            #fff;
        border-radius:    0 3px 3px 0;
        background-color: var(--theme-button-icon);
    }
}

</style>

<script>
export default {
    name:  "ThemeButton",
    props: {
        description: { type: String, required: true },
        disabled:    { type: Boolean, default: false },
        icon:        { type: String, required: true },
    },
};
</script>
