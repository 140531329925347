<template>
    <overlay ref="overlay" @closed="$emit('closed')">
        <div class="settings">
            <ol class="uk-nav">
                <li><i class="icon fal fa-cog" /></li>
                <li class="item radio">
                    <span>{{ $t('dark_mode') }}</span>
                    <toggle-input  v-model="darkMode" />
                </li>
                <li class="item buttons">
                    <theme-button icon="user" :description="$t('log_out')" @click="logOut" />
                </li>
            </ol>
        </div>
    </overlay>
</template>

<style lang="scss">

.settings {
    @include center();

    ol {
        margin-top: 100px;
        width:      400px !important;

        .item {
            margin-bottom: 35px;

            span {
                color: #fff;
            }
        }

        li {
            .icon {
                @include center();
                margin-bottom: 70px;
                font-size:     100px;
                color:         #fff;
            }
        }

    }

    .radio {
        display:         flex;
        justify-content: space-between;

        span {
            font-size: 18px;
        }
    }

    .buttons {
        @include center();
        margin-top: 100px;
    }
}

</style>

<script>
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '@/api';
import Overlay                                                 from '../overlay/Overlay';
import ThemeButton                                             from '../button/ThemeButton';
import ToggleInput                                             from '../input/ToggleInput';

export default {
    name: "SettingsOverlay",
    components: { ThemeButton, ToggleInput, Overlay },
    data() {
        return {
            darkMode:        false,
            settingsOverlay: null,
            showPrices:      false,
        };
    },

    mounted() {
        // set 'app-background' class to body
        let bodyElement = document.body;
        bodyElement.classList.add("app-background");

        // check for active theme
        let htmlElement = document.documentElement;
        let theme = localStorage.getItem("theme");

        if (theme === 'dark') {
            htmlElement.setAttribute('theme', 'dark')
            this.darkMode = true
        } else {
            htmlElement.setAttribute('theme', 'light');
            this.darkMode = false
        }
    },

    watch: {
        darkMode: function () {
            // add/remove class to/from html tag
            let htmlElement = document.documentElement;

            if (this.darkMode) {
                localStorage.setItem("theme", 'dark');
                htmlElement.setAttribute('theme', 'dark');
            } else {
                localStorage.setItem("theme", 'light');
                htmlElement.setAttribute('theme', 'light');
            }
        }
    },

    methods: {
        show()
        {
            this.$refs.overlay.show();
        },

        logOut() {
            window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
            window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
            window.location.assign('/');
        },
    },
};
</script>
