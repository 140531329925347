<template>
    <div class="header" style="z-index: 980;" data-uk-sticky>
        <header :class="large ? 'header-large' : 'header-small'">
            <!-- Main Navbar -->
            <nav class="uk-container uk-navbar">
                <div class="logo" :class="large ? 'uk-navbar-center' : 'uk-navbar-left'">
                    <img
                        :class="large ? 'logo-large' : 'logo-small'"
                        @click="$router.push({ name: 'overview' })"
                        src="../../assets/images/logo.png" alt=""
                    >
                </div>
                <breadcrumbs />
                <div class="menu uk-navbar-right">
                    <a href="#" class="uk-navbar-toggle" @click.prevent="showSettings">
                        <i class="fal fa-bars fa-3x" />
                    </a>
                </div>
            </nav>
        </header>

        <settings-overlay ref="settingsOverlay" />

        <div class="center uk-container uk-container-large">
            <div class="cards">
                <slot name="cards" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">

.header {
    header {
        background: var(--header-background);

        .logo {
            .logo-large {
                width:      200px;
                margin-top: 50px;
            }

            .logo-small {
                width:      150px;
                margin-top: 15px;
            }
        }
    }

    .header-large {
        height: 270px;
    }

    .header-small {
        height: 120px;
    }

    .menu {
        a {
            width:            74px;
            height:           74px;
            margin-top:       20px;
            border-radius:    5px;
            background-color: var(--item-blue-to-dark);

            i {
                color: #fff;
            }
        }
    }
}

.cards {
    margin: -80px auto 0;
    width:  100%;

    &:only-child {
        display:         flex;
        position:        relative;
        justify-content: center;
        box-sizing:      border-box;
    }

    .card {
        border-radius:    5px;
        text-align:       center;
        height:           135px;
        margin:           0 5px;
        min-width:        180px;
        max-width:        180px;
        background-color: var(--item-background);

        header {
            top:                -40px;
            background-color:   var(--item-blue-to-dark);
            @include center();
            height:             70px;
            width:              70px;
            border-radius:      50%;
            border:             2px solid #fff;
            -webkit-box-shadow: 1px 6px 12px -5px gray;
            box-shadow:         1px 6px 12px -5px gray;

            i {
                position:  absolute;
                color:     #fff;
                font-size: 30px;
                left:      22px;
                top:       20px;
            }
        }

        main {
            margin-top: -30px
        }

        progress {
            justify-content: center;
            margin:          5px auto !important;
            width:           70% !important;

            &::-webkit-progress-bar {
                background: $progress-0-11 !important;
            }
        }

        h4,
        h5,
        p {
            margin: 0;
            color:  var(--dynamic-title-color);
        }

        h5,
        span {
            font-size: 12px;
        }

        h4 {
            font-weight: 600;
        }

        h5 {
            font-weight: 700;
        }

        p {
            font-size: 14px;
        }
    }
}

</style>

<script>
import Breadcrumbs     from './breadcrumbs/Breadcrumbs';
import createDialog    from '@/support/createDialog';
import SettingsOverlay from './SettingsOverlay';

export default {
    name: "MainHeader",

    components: {
        Breadcrumbs,
        SettingsOverlay
    },

    props: {
        large:  {
            type:    Boolean,
            default: false
        },
    },

    data()
    {
        return {};
    },

    methods: {
        async showSettings()
        {
            await createDialog(this, SettingsOverlay, {});
        },
    },
};
</script>
