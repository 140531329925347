import Vue from 'vue';
import VueI18Next from '@panter/vue-i18next';

/**
 * @typedef {import('vue/types/vue').CombinedVueInstance} CombinedVueInstance
 */

/**
 * Render a Vue component to a new (unattached) element and return the whole Vue context
 *
 * @param {CombinedVueInstance} caller
 * @param {Object} component
 * @param {Object} props
 * @param {Object} slots
 * @param {Closure} slots.*
 * @returns {CombinedVueInstance}
 */
function renderToElement(caller, component, props, slots = null)
{
    let componentCtor = Vue.extend(component);
    componentCtor.use(VueI18Next);

    let vm = new componentCtor({
        propsData: props,
        i18n: caller.$i18n,
    });

    if (slots !== null) {
        let createElement = vm.$createElement.bind(vm);
        for (let slot in slots) {
            const contents = slots[slot];
            if (contents instanceof Function) {
                vm.$slots[slot] = contents(createElement, vm);
            } else {
                vm.$slots[slot] = contents;
            }
        }
    }

    vm.$mount();

    return vm;
}

/**
 * Shows a dialog component (which must implement the modal logic itself)
 *
 * It renders the component, attaches it to the body, registers a listener to remove it again when it closes,
 * and calls its show() method.
 *
 * TODO: convert into mixin or module so we can remove the caller parameter
 *
 * @param {CombinedVueInstance} caller
 * @param {Object} component
 * @param {Object} props
 * @param {Object} slots
 * @param {Closure} slots.*
 * @returns {Promise<CombinedVueInstance>}
 */
async function createDialog(caller, component, props, slots = null)
{
    let vm = renderToElement(caller, component, props, slots);
    document.body.appendChild(vm.$el);
    vm.$on('closed', () => {
        // clean up
        if (document.body.contains(vm.$el)) {
            document.body.removeChild(vm.$el);
        }
        vm.$destroy();
    });

    await vm.$nextTick();
    await vm.show();

    return vm;
}

export { renderToElement, createDialog as default };
