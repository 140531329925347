<template>
    <label class="toggle-container" :for="inputId">
        <input
            :id="inputId"
            @change="handleChange"
            type="checkbox"
            v-model="checked"
        />
        <div class="slider" />
    </label>
</template>

<style lang="scss">

.toggle-container {
    position: relative;
    display:  inline-block;
    height:   34px;
    width:    60px;

    input {
        display: none;

        &:checked + .slider.uk-switch-big:before {
            transform: translateX(26px) scale(1.2);
        }

        &:checked + .slider {
            background-color: var(--toggle-background);

            &:before {
                transform: translateX(26px);
            }

        }
    }

    .slider {
        background-color:    #fff;
        position:            absolute;
        top:                 0;
        left:                0;
        right:               0;
        border-radius:       500px;
        bottom:              0;
        cursor:              pointer;
        transition-property: background-color;
        transition-duration: .2s;
        box-shadow:          inset 0 0 2px rgba(0, 0, 0, 0.07);

        .uk-switch-on-off {
            background-color: #f0506e;
        }

        &:before {
            content:             '';
            background-color:    #cacaca;
            position:            absolute;
            width:               30px;
            height:              30px;
            left:                2px;
            bottom:              2px;
            border-radius:       50%;
            transition-property: transform, box-shadow;
            transition-duration: .2s;
        }

        .uk-switch-big:before {
            transform:  scale(1.2);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
        }

        .uk-switch-small:before {
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
        }
    }
}
</style>

<script>
export default {
    name: "ToggleInput",

    props: {
        value: { type: Boolean, default: false },
    },

    data()
    {
        return {
            checked: this.value,
        };
    },

    computed: {
        inputId()
        {
            return `toggle-input-${this._uid}`;
        },
    },

    watch: {
        value: function (newValue) {
            this.checked = newValue;
        },
    },

    methods: {
        handleChange()
        {
            this.$emit('input', this.checked);
        },
    },
};
</script>
