<template>
    <div>
        <ol class="breadcrumbs">
            <li
                :key="index"
                @click="handleClick(crumb.name)"
                v-for="(crumb, index) of crumbs"
            >
                {{ crumb.text }}
            </li>
        </ol>
    </div>
</template>

<style scoped lang="scss">

.breadcrumbs {
    margin-left: 20px;
    margin-top:  45px;

    li {
        display:   inline;
        color:     #fff;
        font-size: 20px;

        &:before {
            content:     "\f324";
            padding:     0 5px;
            font-family: "Font Awesome 5 Pro";
            font-size:   18px;
            opacity:     0.5;
        }
    }
}

</style>

<script>
export default {
    name:     "Breadcrumbs",
    computed: {
        crumbs: function () {
            let pathArray = this.$route.path.split("/");
            pathArray.shift()
            return pathArray.reduce((breadcrumbArray, path, idx) => {
                if (this.$route.matched[idx]) {

                    let routeMeta = this.$route.matched[idx].meta;
                    if (Object.keys(routeMeta).length > 0) {

                        breadcrumbArray.push({
                            name: this.$route.matched[idx].name,
                            text: typeof routeMeta.breadCrumbs === 'function'
                                      ? routeMeta.breadCrumbs(this.$route.params)
                                      : routeMeta.breadCrumbs
                            ,
                        });
                    }
                }
                return breadcrumbArray;
            }, [])
        },
    },

    methods: {
        handleClick(name)
        {
            if (this.$route.name !== name) {
                this.$router.push({ name: name });
            }
        },
    },
};
</script>
