<template>
    <div ref="modal" class="uk-modal-full">
        <div class="uk-modal-dialog" :class="{ 'modal-loading': loading }">
            <button
                class="close"
                type="button"
                :disabled="loading"
                @click="hide"
            >
                <i class="fal fa-times" />
            </button>
            <div class="content uk-container uk-container-large" data-uk-height-viewport>
                <slot />
                <div class="item">
                    <img src="../../assets/images/logo.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

.uk-modal-dialog {
    overflow:   auto;
    background: var(--app-overlay-color);

    h3 {
        color:       #fff;
        font-weight: 700;
    }

    .close {
        position:      absolute;
        right:         15px;
        height:        77px;
        width:         73px;
        top:           21px;
        background:    #fff;
        border-radius: 5px;
        border:        none;
        color:         var(--item-blue-to-darkBlue);
        z-index:       10;

        &:disabled {
            color: #ccc;
        }

        i {
            font-size: 40px;
        }
    }

    .content {
        position:    relative;
        padding-top: 50px;
        box-sizing:  border-box;

        .item {
            img {
                position:        relative;
                justify-content: center;
                display:         flex;
                margin:          0 auto;
                top:             50px;
                width:           150px;
            }
        }
    }

    &.modal-loading {
        overflow: hidden;
    }
}
</style>

<script>
import UIkit from 'uikit';

export default {
    name: "Overlay",

    props: {
        loading: { type: Boolean, default: false }
    },
    data()
    {
        return {
            modal: null,
        };
    },
    computed: {
        disabled() {
            return this.loading
                ? 'disabled'
                : null
            ;
        }
    },
    mounted()
    {
        this.modal = UIkit.modal(this.$refs.modal);
        UIkit.util.on(this.$refs.modal, 'hidden', (event) => {
            if (event.target === this.$refs.modal) {
                this.$emit('closed');
            }
        });
    },
    methods: {
        show()
        {
            this.modal.show();
        },

        hide()
        {
            this.modal.hide();
        },
    },
};
</script>
